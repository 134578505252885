<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row class="justify-content-around">

        <!-- nazwa firmy  md 6-->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Router Transition"
            label-cols="6"
          >

            <v-select
              v-model="routerTransition"
              placeholder="Nazwa firmy"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="title"
              :options="companyName"
              :reduce="option => option.value"
            />
          </b-form-group>

        </b-col>

        <!-- przedział czasowy od md3-->
        <b-col
          cols="12"
          md="3"
        >

          <b-form-datepicker
            id="example-datepicker"
            placeholder="data od:"
            class="mb-1"
          />

        </b-col>

        <!-- przedział czasowy do md3-->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-datepicker
            id="example-datepicker2"
            placeholder="data do:"
            class="mb-1"
          />

        </b-col>

        <!-- submit -->
        <b-col
          cols="12"
          md="3"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
          >
            Generuj wyciągi bankowe
          </b-button>
        </b-col>

      </b-row>

    </div>

    <b-table
      :items="items"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak wyciągów bankowych"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(attachment)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          size="sm"
          download
          target="_blank"
          :href="data.item.Attachment"
        >
          Pobierz wyciąg
        </b-button>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            title="Podgląd"
            @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id, type: documentType }})"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              download
              target="_blank"
              :href="data.item.Attachment"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Pobierz</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id, type: documentType } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edytuj</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteBankStatement(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

  </b-card>

</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BFormGroup, BButton, BTable, BFormDatepicker, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BButton,
    BTable,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      documentType: 'bank-statement',
      fields: [
        {
          key: 'Title',
          label: 'Tytuł',
          sortable: false,
        },
        {
          key: 'Bank_account_number',
          label: 'Numer konta',
          sortable: false,
        },
        {
          key: 'created_at',
          label: 'Data dodania',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Akcje',
          sortable: false,
        },

      ],
      items: [
        // {
        //   id: 40,
        //   company_name: 'Company xyz',
        //   nip: '2378821923096',
        //   email: 'info@exmple-company.com',
        //   created: '2021-02-22 10:35',
        //   country: 'Polska',
        //   date_start: '2021-02-22',
        //   date_end: '2021-02-22',
        // },
      ],
      companyName: [{ title: 'Company xyz' }, { title: 'Company xyz' }, { title: 'Company xyz' }],
      routerTransition: null,
    }
  },
  mounted() {
    this.fetchBankStatement()
  },
  methods: {
    async fetchBankStatement(bankStatementType = null) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      if (bankStatementType !== null) {
        config.params = { type: bankStatementType }
      }
      try {
        await axios.get(`${process.env.VUE_APP_API_URL}Bank-statement`, config)
          .then(response => {
            this.items = response.data.data
          })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteBankStatement(id) {
      await axios.delete(`${process.env.VUE_APP_API_URL}Bank-statement/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto wyciąg bankowy',
          '',
          'success',
        )
        this.fetchBankStatement()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
